export const lang_EN = {
  language: "EN",

  menu_home: "Home",
  menu_about: "About",
  menu_media: "Media",
  menu_media_music: "Music",
  menu_media_video: "Video",
  menu_media_gallery: "Gallery",
  menu_contact: "Contact",
  contact_title: "Contact",
  contact_name: "Impresario",
  contact_phone: "+48 508 239 654",
  contact_mail: "duomilonga@gmail.com",
  contact_whatsapp: "whatsapp",
  contact_contact_form: "contact form",
  contact_contact_form_button: "Send",
  about_title: "Duo Milonga",
  about_description_paragraph1:
    "Duo Milonga was formed in 2007 and has already performed in Poland, Germany, Austria, France, Great Britain, Belgium and Canada. In 2007, the Duo won a Grand Prix in Cieszyn(Poland) in the 2nd National Competition for Duos with a Harp; in 2009 won 1st  prize in the 5th International Accordion Competition in Slovenia and, in 2011, was a finalist in the 3rd Concurso Iberico in Madrid (Spain). In 2014 the Duo won 1st Prize in The World Music Competition during the III International Harp Festival in Wales (Great Britain).",
  about_description_paragraph2:
    "The Duo has also performed in many international festivals, including:",
  about_description_paragraph_festivals: [
    "Blaue Harfentage Festival at the Hochschule für Musik in Munich",
    "The Edinburgh International Fringe Festival,",
    "International Harp Festival in Katowice,",
    "International Festival of Young Laureates of Music Competitions,",
    "Tango Marathon The Strawberry Edition,",
    "Festival Music in the monastery in Łagiewniki",
    "Jeremi Przybora Festival – Stacja Kutno",
    "Musica Moderna session at the Academy of Music in Łódź",
    "and in many festivals of chamber and tango music.",
  ],
  about_description_paragraph3:
    "A great privilege for the group was an invitation to perform the recital during the 11th World Harp Congress in Vancouver (Canada) in July 2011. For many years, the Duo has also played regular concerts in Salzburg for the Foundation Yehudi Menuhin Live Music Now. Duo Milonga creates a great richness of sounds blending the delicate character of the harp with the strong but melodic sound of the accordion. This innovative and original combination gives a contemporary variation and a new dimension to the music of old masters. The Duo&#39;s repertoire consists of various compositions and traditional musical interpretations, as well as 21st century pieces and the duo&#39;s own arrangements of Spanish music and Argentinian tangos. For tango perfomances, Duo Milonga was honoured with a certificate by the National Tango Academy in Buenos Aires.",
  about_description_paragraph4:
    "A great privilege for the group was an invitation to perform the recital during the 11th World Harp Congress in Vancouver (Canada) in July 2011. For many years, the Duo has also played regular concerts in Salzburg for the Foundation Yehudi Menuhin Live Music Now. Duo Milonga creates a great richness of sounds blending the delicate character of the harp with the strong but melodic sound of the accordion. This innovative and original combination gives a contemporary variation and a new dimension to the music of old masters. The Duo&#39;s repertoire consists of various compositions and traditional musical interpretations, as well as 21st century pieces and the duo&#39;s own arrangements of Spanish music and Argentinian tangos. For tango perfomances, Duo Milonga was honoured with a certificate by the National Tango Academy in Buenos Aires.",
  about_description_paragraph5:
    "The band cooperates with the Argentinian singer, Oscar Ernesto Ovejero, creating the Colours of Tango project, which regularly performs in Poland and across Europe, and which released an album in 2015 through DUX Recording Producers.",
  about_description_paragraph6:
    "Duo Milonga also appears in concerts with the soloist of the Musical Theater in Łódź, Paweł Erdman, creating a performance entitled &quot;Muzyczny Przybornik Jeremiego”. The project involves innovative arrangements of the most famous and popular songs in Poland, including Kabaret Starszych Panów, by Jeremi Przybora and Jerzy Wasowski.",
  about_aneta_name: "Aneta Salwińska",
  about_aneta_paragraph1:
    "Aneta graduated from the Academy of Music Karol Szymanowski in Katowice in the harp class of prof.Helga Storck whilst also studying at the Universität Mozarteum in Salzburg (Austria) at the Faculty of Vocal and Instrumental Pedagogy, specializing in harp. In 2020, she obtained the degree of Doctor of Arts, presenting a dissertation on transcription in harp music. She took part in master classes conducted by, among others, prof. Helga Storck, prof. Klaus Storck, prof. Isabelle Moretti, prof. Cristina Braga, prof. David Watkins, Park Stickney, and Prof. Imogen Barford.",
  about_aneta_paragraph2:
    "Aneta Salwińska performs as a soloist and chamber musician, as well as in orchestras, with Polish harp concerts an important part of her repertoire. As part of the 5th International Harp Festival in Katowice in 2013, together with Adam Krzeszowiec, she performed Concerto da camera by Krzysztof Meyer for harp, cello and orchestra, with the accompaniment of the Silesian Chamber Orchestra.In 2014, during the 22nd International Festival of Chamber Music, she performed Sinfonia Concertante by Andrzej Panufnik for flute and harp together with with Łukasz Zimnik - also accompanied by the Silesian Chamber Orchestra. In 2016, as part of the Inaugural Concert of the 8th International Harp Festival in Katowice, together with Adam Krzeszowiec, she performed Scenes for harp, cello and orchestra by Tadeusz Baird, which she also recorded with the National Polish Radio Symphony Orchestra in Katowice.",
  about_aneta_paragraph3:
    "Aneta has had the opportunity to cooperate with numerous symphony orchestras, such as the Philharmonic T. Szeligowski in Poznań, the Philharmonic of A. Rubinstein in Łódź, the Grand Theater in Łódź, the Lower Silesian Philharmonic in Jelenia Góra, the J. Elsner in Opole, Podkarpacka Philharmonic in Rzeszów, as well as with the Płock Symphony Orchestra. W. Lutosławski and Collegium Musicum in Salzburg.",
  about_aneta_paragraph4:
    "She is also willing to devote herself to teaching work and has worked as an assistant in the harp class at the Karol Szymanowski Academy of Music. K. Szymanowski in Katowice. She has conducted courses for children and teenagers many times, e.g. as part of the Summer Masterclass in Radziejowice, the 1st National Summer Harp Course in Bytom, as well as master classes for harpists at the Palace in Rybna and during the 24th International Summer Academy Moulin d&#39;Ande (France). She currently teaches the harp class at the Stanisław Moniuszko Music School in Łódź.",
  about_aneta_paragraph5:
    "In 2009, she received the Artistic Scholarship of the Marshal of the Łódź Voivodeship for special artistic achievements. In the years 2008-2012 she was awarded by the FoundationYehudi Menuhin Live Music Now, for which she performed many concerts in Germany and Austria.",
  about_aneta_paragraph6:
    "Since 2007, together with the accordionist Konrad Salwiński, she has been creating, and performing in Duo Milonga, which has won awards at national and international music competitions, and received glowing reviews from concerts across Europe.",
  about_konrad_name: "Konrad Salwiński",
  about_konrad_paragraph1:
    "Konrad started learning to play the accordion at the age of 7 at the State Music School in Starachowice, as part of Marek Marecki's class. In 2011, he graduated from the Academy of Music in Łódź in the class of prof. Zbigniew Ignaczewski. In the years 2008 - 2011 he studied at the Universität für Musik und darstellende Kunst in Graz (Austria) in the class of prof. Janne Rättyä.",
  about_konrad_paragraph2:
    "He has participated in the International Music Interpretation Course many times, where he improved his skills under the guidance of some of the most outstanding teachers from Poland and across the world.",
  about_konrad_paragraph3:
    "He is a laureate of many national and international accordion competitions:",
  about_konrad_festivals: [
    "1st prize in the category of soloists and 1st prize in the category of chamber ensembles at the Festival of Accordion Music in Przemyśl, 1998;",
    "3rd prize at the International Accordion Competition in Poprad (Slovakia), 1999;",
    "5th place in the International Accordion Competition in Klingenthal (Germany), 2000;",
    "1st prize and the title of &quot;Absolute Winner&quot; in the International Accordion Competition in Dunajska Streda, Slovakia, 2000;",
    "Tango Maratonu The Strawberry Edition,",
    "5th place in the International Accordion Competition in Castelfidardo (Italy), 2000;",
    "1st prize at the International Accordion Competition in Poprad (Slovakia), 2001;",
    "2nd prize in the Vilnius International Accordion Competition, 2002;",
    "2nd prize at the 12th International Festival of Accordion Music in Przemyśl, 2003;",
    "3rd prize in the 6th International Accordion Meetings Sanok 2004;",
    "1st prize at the 13th International Festival of Accordion Music in Przemyśl, 2004;",
    "1st prize in the category of chamber ensembles at the 32nd International Accordion Competition in Pula (Croatia), 2007;",
    "1st prize in the category of soloists and 1st prize in the category of chamber ensembles at the International Festival Accordion Competition in Moravskie Toplice (Slovenia), 2007;",
    "1st prize in the category of chamber ensembles at the International Accordion Competition in Poprad (Slovakia), 2007;",
    "1st prize at the International Accordion Competition in Vienna, 2008;",
    "1st prize in the category of chamber ensembles at the 8th International Accordion Festival Sanok 2008;",
    "4th place in the category of chamber ensembles at the 45th International Accordion Competition in Klingenthal (Germany), 2008;",
    "honorable mention in the 2nd National Accordion Competition named after Andrzej Krzanowski in Czechowice-Dziedzice, 2008;",
    "1st prize in the category of chamber ensembles at the 33rd International Accordion Competition in Castelfidardo, 2008;",
    "1st prize in the category of chamber ensembles at the 11th Accordion Music Presentations in Wrocław, 2008;",
    "honorable mention in the 3rd International Music Competition named after Michał Spisak in Dąbrowa Górnicza, 2009;",
  ],
  about_konrad_paragraph4:
    `In 1998, he received the 2nd degree Award of the Mayor of Starachowice. In 2000, 2006 and 2008 he was awarded a scholarship by the Ministry of Culture and National Heritage, and, since 1998, he has been awarded by the National Children's Fund. In 2007, he won the 4th edition of the "Young Poland"; scholarship program of the Ministry of Culture and National Heritage, and in 2008 and 2011, he received a scholarship from the Marshal of the Łódź Voivodeship. He often performs in concerts in Łódź and the Łódź region, as well as in other cities in Poland and around the world. Currently, he runs accordion classes at the State Primary Music School and the Secondary Music School in Zgierz, with many of his pupils enjoying success at national and international music competitions.`,
  about_more: "more...",
  about_less: "less...",
};

export const lang_PL = {
  language: "PL",
  menu_home: "Home",
  menu_about: "O Nas",
  menu_media: "Media",
  menu_media_music: "Muzyka",
  menu_media_video: "Wideo",
  menu_media_gallery: "Galeria",
  menu_contact: "Kontakt",
  contact_title: "Kontakt",
  contact_name: "Impresariat",
  contact_phone: "+48 508 239 654",
  contact_mail: "duomilonga@gmail.com",
  contact_whatsapp: "whatsapp",
  contact_contact_form: "Formularz kontaktowy",
  contact_contact_form_button: "Wyślij",
  about_title: "Duo Milonga",
  about_description_paragraph1:
    "Duet powstał w 2007 roku i od początku istnienia koncertował już w Polsce, Niemczech, Austrii, Francji, Wielkiej Brytanii, Belgii i Kanadzie. W 2007 roku Duo zdobyło Grand Prix II Ogólnopolskiego Konkursu Duetów z Harfą w Cieszynie, w 2009 roku I nagrodę na V Międzynarodowym Konkursie Akordeonowym w Moravskich Toplicach (Słowenia), a w 2011 roku było finalistą III Concurso Iberico w Madrycie (Hiszpania). Duo jest także laureatem I nagrody w World Music Competition w ramach III Międzynarodowego Festiwalu Harfowego w Walii w 2014 roku.",
  about_description_paragraph2:
    "Duo Milonga występowało podczas wielu międzynarodowych festiwali, między innymi w ramach:",
  about_description_paragraph_festivals: [
    "Festiwalu Blaue Harfentage w Hochschule für Musik w Monachium,",
    "Międzynarodowego Festiwalu The Edinburgh Festival Fringe,",
    "Międzynarodowego Festiwalu Harfowego w Katowicach,",
    "Międzynarodowego Festiwalu Młodych Laureatów Konkursów Muzycznych,",
    "Tango Maratonu The Strawberry Edition,",
    "Festiwalu Muzyka w klasztorze w Łagiewnikach,",
    "Festiwalu Jeremiego Przybory - Stacja Kutno,",
    "Sesji Musica Moderna w Akademii Muzycznej w Łodzi,",
    "wielu festiwali muzyki kameralnej i tangowej.",
  ],
  about_description_paragraph3:
    "Dużym wyróżnieniem dla zespołu było zaproszenie do wykonania recitalu podczas XI Światowego Kongresu Harfowego w Vancouver (Kanada) w lipcu 2011 roku. Przez wiele lat duet grał także regularne koncerty dla Fundacji im. Yehudi Menuhina Live Music Now w Salzburgu.",
  about_description_paragraph4:
    "Duo Milonga to zaskakujące mnogością barw połączenie delikatnego brzmienia harfy z wyrazistym, a zarazem lirycznym dźwiękiem akordeonu. To niespotykane zestawienie, charakterystyczne bardziej dla muzyki współczesnej, potrafi jednocześnie nadać zupełnie nowy kształt muzyce dawnych mistrzów. W repertuarze zespołu znajdują się różnorodne kompozycje, od opracowań muzyki dawnej po utwory XXI wieczne, a także własne aranżacje muzyki hiszpańskiej oraz tang argentyńskich. Zespół, za swoją działalność związaną z tangiem, został uhonorowany certyfikatem Narodowej Akademii Tanga w Buenos Aires.",
  about_description_paragraph5:
    "Zespół współpracuje z argentyńskim wokalistą Oscarem Ernesto Ovejero, tworząc projekt Colours of tango regularnie koncertujący w Polsce i Europie, którego płyta ukazała się w 2015 roku nakładem wydawnictwa DUX.",
  about_description_paragraph6:
    "Duo Milonga koncertuje także z solistą Teatru Muzycznego w Łodzi, Pawłem Erdmanem, tworząc spektakl „Muzyczny Przybornik Jeremiego”. Projekt ukazuje nowatorskie aranżacje najbardziej znanych i lubianych piosenek słynnego Kabaretu Starszych Panów, autorstwa Jeremiego Przybory i Jerzego Wasowskiego.",
  about_aneta_name: "Aneta Salwińska",
  about_aneta_paragraph1:
    "absolwentka Akademii Muzycznej im. Karola Szymanowskiego w Katowicach w klasie harfy prof. Helgi Storck. Równolegle studiowała także w Universität Mozarteum w Salzburgu (Austria) na wydziale Pedagogiki Wokalno-Instrumentalnej ze specjalnością: harfa. W 2020 roku uzyskała stopnień doktora sztuki prezentując dysertację na temat transkrypcji w muzyce harfowej. Brała udział w kursach mistrzowskich prowadzonych m. in. przez: prof. Helgę Storck, prof. Klausa Storck, prof. Isabelle Moretti, prof. Cristinę Bragę, prof. Davida Watkinsa, Parka Stickney’a, prof. Imogen Barford i.in.",
  about_aneta_paragraph2:
    "Występuje jako solistka i kameralistka, a także w orkiestrach. Ważny punkt repertuaru   Anety Salwińskiej stanowią polskie koncerty na harfę. W ramach V Międzynarodowego Festiwalu Harfowego w Katowicach 2013, wykonała wraz z Adamem Krzeszowcem Concerto da camera na harfę, wiolonczelę i orkiestrę Krzysztofa Meyera z towarzyszeniem Śląskiej Orkiestry Kameralnej, a w roku 2014 podczas XXII Międzynarodowego Festiwalu Muzyki Kameralnej wykonała wraz z Łukaszem Zimnikiem Sinfonia Concertante na flet i harfę Andrzeja Panufnika - także z towarzyszeniem Śląskiej Orkiestry Kameralnej. W 2016 roku, w ramach Koncertu Inauguracyjnego VIII Międzynarodowego Festiwalu Harfowego w Katowicach wykonała wraz z Adamem Krzeszowcem Sceny na harfę, wiolonczelę i orkiestrę Tadeusza Bairda, który to utwór nagrała także z towarzyszeniem Narodowej Orkiestry Symfonicznej Polskiego Radia w Katowicach.",
  about_aneta_paragraph3:
    "Miała okazję współpracować z licznymi orkiestrami symfonicznymi, m.in. Filharmonii im. T. Szeligowskiego w Poznaniu, Filharmonii im. A. Rubinsteina w Łodzi, Teatru Wielkiego w Łodzi, Filharmonii Dolnośląskiej w Jeleniej Górze, Filharmonii im. J. Elsnera w Opolu, Filharmonii Podkarpackiej w Rzeszowie, a także z Płocką Orkiestrą Symfoniczną im. W. Lutosławskiego oraz Collegium Musicum w Salzburgu.",
  about_aneta_paragraph4:
    "Chętnie poświęca się także pracy pedagogicznej. Pracowała na stanowisku asystenta w klasie harfy Akademii Muzycznej im. K. Szymanowskiego w Katowicach. Wielokrotnie prowadziła kursy dla dzieci i młodzieży, m. in. w ramach Summer Masterclass w Radziejowicach, I Ogólnopolskiego Letniego Kursu Harfowego w Bytomiu, jak również kursy mistrzowskie dla harfistów w Pałacu w Rybnej oraz podczas XXIV International Summer Academy Moulin d’Ande (Francja). Obecnie prowadzi klasę harfy w Zespole Szkół Muzycznych im. Stanisława Moniuszki w Łodzi.",
  about_aneta_paragraph5:
    "W 2009 roku otrzymała Stypendium Artystyczne Marszałka Województwa Łódzkiego za szczególne osiągnięcia artystyczne. A w latach 2008-2012 była podopieczną Fundacji im. Yehudi Menuhina Live Music Now, dla której wykonała wiele koncertów na terenie Niemiec i Austrii.",
  about_aneta_paragraph6:
    "Od 2007 roku wraz z akordeonistą Konradem Salwińskim tworzy duet instrumentalny Duo Milonga, nagradzany na ogólnopolskich i międzynarodowych konkursach muzycznych, a także koncertujący w wielu krajach Europy.",
  about_konrad_name: "Konrad Salwiński",
  about_konrad_paragraph1:
    'Naukę gry na akordeonie rozpoczął w wieku 7 lat w Państwowej Szkole Muzycznej w Starachowicach, w klasie Marka Mareckiego. W 2011 roku ukończył Akademię Muzyczną w Łodzi w klasie prof. Zbigniewa Ignaczewskiego. W latach 2008 - 2011 studiował także w Universität für Musik und darstellende Kunst w Grazu (Austria) w klasie prof. Janne Rättyä.',
  about_konrad_paragraph2:
    'Wielokrotnie uczestniczył w "Międzynarodowym Kursie Interpretacji Muzycznej", gdzie doskonalił swoje umiejętności pod okiem najznakomitszych pedagogów z Polski i świata.',
  about_konrad_paragraph3:
    "Jest laureatem wielu ogólnopolskich i międzynarodowych konkursów akordeonowych:",
  about_konrad_festivals: [
    "I nagroda w kategorii solistów oraz I nagroda w kategorii zespołów kameralnych na Festiwalu Muzyki Akordeonowej w Przemyślu, 1998 r.;",
    "III nagroda w Międzynarodowym Konkursie Akordeonowym w Popradzie (Słowacja), 1999 r.;",
    "V miejsce w Międzynarodowym Konkursie Akordeonowym w Klingenthal (Niemcy), 2000 r.;",
    "I nagroda oraz tytuł „Absolute Winner” w Międzynarodowym Konkursie Akordeonowym w Dunajskiej Stredzie na Słowacji, 2000r.;",
    "Tango Maratonu The Strawberry Edition,",
    "V miejsce w Międzynarodowym Konkursie Akordeonowym w Castelfidardo (Włochy), 2000 r.;",
    "I nagroda w Międzynarodowym Konkursie Akordeonowym w Popradzie (Słowacja), 2001 r.;",
    "II nagroda w Międzynarodowym Konkursie Akordeonowym Wilnie, 2002 r.;",
    "II nagroda w XII Międzynarodowym Festiwalu Muzyki Akordeonowej w Przemyślu, 2003 r.;",
    "III nagroda w VI Międzynarodowych Spotkaniach Akordeonowych Sanok 2004;",
    "I nagroda w XIII Międzynarodowym Festiwalu Muzyki Akordeonowej w Przemyślu, 2004 r.;",
    "I nagroda w kategorii zespołów kameralnych w 32. Międzynarodowym Konkursie Akordeonowym w Puli (Chorwacja), 2007 r.;",
    "I nagroda w kategorii solistów oraz I nagroda w kategorii zespołów kameralnych w Międzynarodowym Konkursie Akordeonowym w Moravskich Toplicach (Słowenia), 2007 r.;",
    "I nagroda w kategorii zespołów kameralnych w Międzynarodowym Konkursie Akordeonowym w Popradzie (Słowacja), 2007 r.;",
    "- I nagroda w Międzynarodowym Konkursie Akordeonowym w Wiedniu, 2008 r.;",
    "I nagroda w kategorii zespołów kameralnych w VIII Międzynarodowym Festiwalu Akordeonowym Sanok 2008;",
    "IV miejsce w kategorii zespołów kameralnych w XLV Międzynarodowym Konkursie Akordeonowym w Klingenthal (Niemcy), 2008 r.;",
    "wyróżnienie w II Ogólnopolskim Konkursie Akordeonowym im. Andrzeja Krzanowskiego w Czechowicach-Dziedzicach, 2008 r.;",
    "I nagroda w kategorii zespołów kameralnych w 33. Międzynarodowym Konkursie Akordeonowym w Castelfidardo, 2008 r.;",
    "I nagroda w kategorii zespołów kamerlanych w XI Prezentacjach Muzyki Akordeonowej we Wrocławiu, 2008 r.;",
    "wyróżnienie w III Międzynarodowym Konkursie Muzycznym im. Michała Spisaka w Dąbrowie Górniczej, 2009 r.;",
  ],
  about_konrad_paragraph4:
    'W roku 1998 otrzymał Nagrodę II st. Prezydenta Miasta Starachowic. W 2000, 2006 i 2008 r. był stypendystą Ministra Kultury i Dziedzictwa Narodowego, a od 1998 r. jest podopiecznym Krajowego Funduszu na rzecz Dzieci. W roku 2007 został laureatem IV edycji programu stypendialnego Ministra Kultury i Dziedzictwa Narodowego "Młoda Polska", a w 2008 oraz 2011 roku stypendystą Marszałka Województwa Łódzkiego. Często koncertuje w Łodzi i regionie łódzkim, a także innych miastach w Polsce i na świecie. Obecnie prowadzi klasy akordeonu w Państwowej Szkole Muzycznej I stopnia oraz Społecznej Szkole Muzycznej II stopnia w Zgierzu, a jego podopieczni odnoszą sukcesy na ogólnopolskich i międzynarodowych konkursach muzycznych.',
  about_more: "więcej...",
  about_less: "mniej...",
};
